const data = JSON.parse(window.localStorage.getItem("role") as any)
const activeItems = data?.page_permissions

const SettingMenuConfig = [
  {
    heading: 'setting',
    route: '/setting',
    permission: 'all',
    pages: [
      {
        sectionTitle: 'environmentSettings',
        route: '/setting/environment-settings',
        permission: activeItems[6].is_active ? 'all' : '',
        sub: [
          // {
          //   heading: 'tenant',
          //   route: '/setting/environment-settings/tenant',
          //   permission: 'all',
          // },
          {
            heading: 'workspace',
            route: '/setting/environment-settings/workspace',
            permission: activeItems[6].is_active && activeItems[6].permissions[0].is_active ? 'all' : '',
          },
          {
            heading: 'channel',
            route: '/setting/environment-settings/channel',
            permission: activeItems[6].is_active && activeItems[6].permissions[4].is_active ? 'all' : ''
          },
        ],
      },
      {
        sectionTitle: 'organization',
        route: '/setting/organization',
        permission: activeItems[8].is_active || activeItems[7].is_active ? 'all' : '',
        sub: [
          {
            heading: 'generalSetting',
            route: '/setting/organization/general-setting',
            permission: activeItems[7].is_active ? 'all' : '',
          },
          {
            heading: 'members',
            route: '/setting/organization/members',
            permission: activeItems[8].is_active && activeItems[8].permissions[0].is_active ? 'all' : ''
          },
          {
            heading: 'rolePermission',
            route: '/setting/organization/role-and-permission',
            permission: activeItems[8].is_active && activeItems[8].permissions[5].is_active ? 'all' : ''
          },
        ],
      },
      {
        sectionTitle: 'assetStatus',
        route: '/setting/asset-status',
        permission: activeItems[9].is_active ? 'all' : '',
        sub: [
          {
            heading: 'landStatus',
            route: '/setting/category-value/631689248c130d3fa0416184',
            permission: activeItems[9].is_active ? 'all' : '',
          },
          {
            heading: 'earningStatusPage.earningStatus',
            route: '/setting/category-value/6316f26a8c130d3fa04163c7',
            permission: activeItems[9].is_active ? 'all' : '',
          },
          {
            heading: 'legalStatus',
            route: '/setting/category-value/6316769d8c130d3fa041611c',
            permission: activeItems[9].is_active ? 'all' : '',
          },
          {
            heading: 'assetDescriptionStatus',
            route: '/setting/category-value/6317d3195f05cc92cf2d5540',
            permission: activeItems[9].is_active ? 'all' : '',
          },
          {
            heading: 'Status Produktivitas',
            route: '/setting/category-value/6317d89a5f05cc92cf2d5572',
            permission: activeItems[9].is_active ? 'all' : '',
          },
          // {
          //   heading: 'Pengguna',
          //   route: '/setting/category-value/6317dd3e5f05cc92cf2d5596',
          // },
          {
            heading: 'Status Pengelolaan',
            route: '/setting/category-value/6317df375f05cc92cf2d55ce',
            permission: activeItems[9].is_active ? 'all' : '',
          }
        ],
      },
      {
        sectionTitle: 'assetCategory',
        route: '/setting/asset-status',
        permission: activeItems[9].is_active ? 'all' : '',
        sub: [
          // {
          //   heading: 'kindAsset',
          //   route: '/setting/asset-category/category',
          // },
          {
            heading: 'kindAsset',
            route: '/setting/category-value/631577ec8c130d3fa0415fe8',
            permission: activeItems[9].is_active ? 'all' : '',
          },
          {
            heading: 'Dimensi Aset',
            route: '/setting/category-value/asset-dimension',
            permission: activeItems[9].is_active ? 'all' : '',
          },
          {
            heading: 'Upaya Pemanfaatan',
            route: '/setting/category-value/6317e2b55f05cc92cf2d5607',
            permission: activeItems[9].is_active ? 'all' : '',
          },
          {
            heading: 'kindDocument',
            route: '/setting/asset-category/document',
            permission: activeItems[9].is_active ? 'all' : '',
          },
          {
            heading: 'kindCurrency',
            route: '/setting/asset-category/currency',
            permission: activeItems[9].is_active ? 'all' : '',
          },
        ],
      },
      {
        sectionTitle: 'common.admin',
        route: '/setting/admin',
        permission: 'setting.admin.accesstoken_edit',
        sub: [
          {
            heading: 'tokenSetting',
            route: '/setting/admin/access-token',
            permission: 'setting.admin.accesstoken_edit',
          },
        ],
      },
    ],
  },
];

export default SettingMenuConfig;
