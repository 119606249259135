
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components";
import { MenuComponent } from "@/assets/ts/components";
import { asideMenuIcons } from "@/core/helpers/config";
import SettingMenuConfig from "../../core/config/SettingMenuConfig";
import { useStore } from "vuex";
import { ParseJwt } from "@/core/helpers/helps";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "menu-project-update",
  components: {},
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);
    const store = useStore();

    const routeBefore = computed(() => store.getters.getPrjUpdateNavFrom);

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const menus = [
      {
        route: `/profile/detail`,
        heading: "myprofile",
      },
      {
        route: `/profile/password`,
        heading: "changePass",
      },
    ];
      

    const logout = () => {
       const token = localStorage.getItem("id_token");
      const dataUser = ParseJwt(token);
      const payload = {
        user_id: dataUser.sub,
        exp: dataUser.exp,
      };
      // store.dispatch(Actions.FCM_LOGOUT, payload);
      store
        .dispatch(Actions.LOGOUT)
        .then(() => (window.location.href = "/sign-in"));
    }

    return {
      logout,
      menus,
      routeBefore,
      translate,
      hasActiveChildren,
      SettingMenuConfig,
      asideMenuIcons,
    };
  },
});
