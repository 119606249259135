
import { defineComponent, onMounted, onUpdated } from "vue";
import { ToggleComponent } from "@/assets/ts/components";
import { asideTheme } from "@/core/helpers/config";
import MenuProfile from "@/layout/aside/MenuProfile.vue";

export default defineComponent({
  name: "aside-profile",
  components: {
    MenuProfile,
  },
  props: {
    lightLogo: String,
    darkLogo: String,
  },
  setup() {
    onMounted(() => {
      ToggleComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
    });

    return {
      asideTheme,
    };
  },
});
