
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  provide,
  ref,
  watch,
} from "vue";
import KTLoader from "@/components/Loader.vue";
import KTHeader from "@/layout/header/Header.vue";
import KTFooter from "@/layout/footer/Footer.vue";
import KTToolbarOut from "@/layout/toolbar/ToolbarOut.vue";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import HtmlClass from "@/core/services/LayoutService";
import {
  DrawerComponent,
  MenuComponent,
  ScrollComponent,
} from "@/assets/ts/components";
import { removeModalBackdrop } from "@/core/helpers/dom";
import {
  asideEnabled,
  breadCrumbBar,
  contentWidthFluid,
  loaderEnabled,
  loaderLogo,
  subheaderDisplay,
  themeDarkLogo,
  themeLightLogo,
  toolbarDisplay,
} from "@/core/helpers/config";
import AsideProjectUpdate from "@/layout/aside/AsideProfile.vue";
import { Actions } from "@/store/enums/StoreEnums";
import {
  PrjUpdateDrawerModeEnum,
  PrjUpdateTabEnum,
} from "@/store/modules/ProjectUpdateModule";
import { capitalize, filters } from "@/core/helpers/helps";
import ToolbarProjectUpdate from "@/layout/toolbar/ToolbarProfile.vue";
import { setAllertError } from "@/core/services/Alert";

export default defineComponent({
  name: "Prodile",
  components: {
    AsideProjectUpdate,
    KTLoader,
    KTHeader,
    KTFooter,
    KTToolbarOut,
    KTScrollTop,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    const project = ref(null);
    provide("project", project);
    const getProject = async () => {
      await store
        .dispatch(Actions.GET_SHOW_PROJECT, route.params.id)
        .then((data) => {
          project.value = data;
          store.dispatch(Actions.UPDATE_PROJECT_UPDATE_CHARTER, data);
        })
        .catch(() => setAllertError(store.getters.getErrors));
    };

    onMounted(() => {
      //check if current user is authenticated
      if (!store.getters.isUserAuthenticated) {
        // router.push({ name: "sign-in" });
      }

      DrawerComponent.bootstrap();
      ScrollComponent.bootstrap();
      DrawerComponent.updateAll();
      ScrollComponent.updateAll();
      // getProject();
    });

    onUnmounted(() => {
      closeDrawer();
    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);

        DrawerComponent.hideAll();

        // check if current user is authenticated
        if (!store.getters.isUserAuthenticated) {
          // router.push({ name: "sign-in" });
        }

        removeModalBackdrop();
      }
    );

    const submitting = computed(() => store.getters.getPrjUpdateIsSubmitting);

    const drawer = computed({
      get: () => store.getters.getPrjUpdateDrawerState,
      set: (val) =>
        store.dispatch(Actions.UPDATE_PROJECT_UPDATE_DRAWER_STATE, val),
    });
    const drawerMode = computed({
      get: () => store.getters.getPrjUpdateDrawerMode,
      set: (val) =>
        store.dispatch(Actions.UPDATE_PROJECT_UPDATE_DRAWER_MODE, val),
    });
    const closeDrawer = () => {
      store.dispatch(Actions.UPDATE_PROJECT_UPDATE_DRAWER_STATE, false);
      store.dispatch(
        Actions.UPDATE_PROJECT_UPDATE_DRAWER_MODE,
        PrjUpdateDrawerModeEnum.ADD
      );
    };

    const activeTab = computed(() => store.getters.getPrjUpdateActiveTab);
    const editPartnerForm = ref();
    const submitForm = () => {
      if (activeTab.value === PrjUpdateTabEnum.PARTNER) {
        editPartnerForm.value.onSubmit();
      }
    };

    const handleSuccess = () => {
      store.dispatch(Actions.PARTNER_LIST, {
        top_type: "PARTNER",
        charter_id: route.params.id,
        ...filters({}),
      });
      closeDrawer();
    };
    return {
      route,
      project,
      handleSuccess,
      submitForm,
      editPartnerForm,
      capitalize,
      activeTab,
      PrjUpdateTabEnum,
      PrjUpdateDrawerModeEnum,
      closeDrawer,
      drawer,
      drawerMode,
      submitting,
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      breadCrumbBar,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
    };
  },
});
